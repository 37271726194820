.sellframe{
    display: flex;
    width:80%;
    left:10%;
    position: relative;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content:space-evenly;
    margin-top:30px;
}

.sellpoint{
    max-width: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sellpoint_title{
    font-size: 24px;
    font-weight:bolder;
}

.sellpoint_desc{
    font-weight:lighter;
    font-size: 16px;
}

.sellpoint_img{
    height: 50px;
}