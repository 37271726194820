
.App {
  text-align: center;
}

.podax_logo{
  padding: 20px;
}

.podax_exp{
 font-size: 20px;
 text-align: center; 
}

.space_header{
  height: 70px;
}
.quote_italic{
  font-style: italic;
}

.podax_quote{
  font-size: 15px;
  text-align: center; 
  font-weight: lighter;
  white-space: pre-wrap;
}

.breaker{
  width: 80%;
  left: 10%;
  position: relative;
  height: 1px;
  background-color: rgb(206, 206, 206);
  margin-top:30px;
  margin-bottom:30px;
}