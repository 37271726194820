.caption_one_frame{
    position: relative;
    width: 80%;
    left: 10%;
}

.caption_one_medtit{
    font-size:24px;
    margin-bottom: 40px;

}
.caption_one_title{
    font-weight: bolder;
    white-space: pre-wrap;
    font-size:42px
}

.caption_one_desc{
    white-space: pre-wrap;
    font-weight: 500;
    color:rgb(255, 255, 255);
    background-color: black;
    border-radius: 5px;
    padding-top: 40px;
    padding-bottom:40px;
    padding-left: 25px;
    padding-right:25px;

}