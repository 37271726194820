.gitlogo{
    height:35px;
    cursor: pointer;
    top: 6px;
    position:relative;
    padding-right: 30px;
}

.app_links_holder{
    width: 100%;
    height:50px;
    display: flex;
    justify-content: flex-end;
    background-color: rgb(0, 0, 0);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgb(59, 59, 59);
    position: fixed;
    z-index: 9;
    top: 0px;
}

.app_links_text{
    position: relative;
    top: -3px;
    color: white;
    padding-right: 10px;
}

.subholder{
    display: flex;
    flex-direction: row;
}