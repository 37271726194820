

.dl_opt_frame{
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap:wrap;
    justify-content:space-evenly;
    align-items: center;
    width:100%;
}

.dl_opt_enabled{
    max-width: 300px;
    max-height: 62px;
    min-width: 300px;
    min-height: 62px;
    background-color: black;
    margin-bottom:15px;
    margin-top:15px;
    cursor: pointer;
}

.dl_opt_disabled{
    max-width: 300px;
    max-height: 62px;
    min-width: 300px;
    min-height: 62px;
    background-color: rgb(112, 112, 112);
    margin-bottom:15px;
    margin-top:15px;
    cursor: not-allowed;
}

.dl_text{
color: white;
position: relative;
top:0px;
font-size: 20px;
text-align: center;
}